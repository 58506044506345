import React from 'react';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href='mailto:info@ouway.co'>Contact </a>
        </li>
        <li>
          <a href='https://ouway.co/'>About us </a>
        </li>
        <li>
          <a href='https://ouway.co/terms-conditions'>FAQ </a>
        </li>
        <li>
          <a href='https://ouway.co/privacy-policy'>Support </a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;